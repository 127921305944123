<template>
  <div style="padding: 0 20px;">
    <div class="title">{{$t('pledgeList.title11')}}</div>
      <!-- 切换 -->
    <div class="pledge" >
      <div class="pledge_box" style="padding-bottom: 10px;margin-bottom:20px;" >

        <div class="pledge_top flex_lr">
          <div class="flex_row">
            <div style="position: relative;">
              <div style="position: absolute;color:#15B3B1;font-weight: bold;top:35%;left:18%;font-size:0.8rem;"></div>
              <img style="width: 50px;margin-right: 10px;" src="@/image/pledge_bg.png" alt="">
            </div>
            <div>
              <div style="font-size: 0.7rem;">min</div>
              <div style="font-size: 1rem;">1 MT/{{$t('pledgeList.text1')}}</div>
            </div>
          </div>
          <div style="position: relative;">
            <img style="width: 50px;" src="@/image/pledge_05.png" alt="">
            <div class="pledge_day y_center flex_center">
              <div style="font-size:1.2rem;">180</div>
              <div style="font-size:0.7rem;">DAY</div>
            </div>
          </div>
        </div>
        <!-- 托管收益/质押总计 -->
        <div class="flex_lr" style="padding: 10px 0;">
          <div class="flex_row" style="width:0;flex:1;">

            <div style="width: 45%;">
              <div class="pledge_title">{{$t('pledgeList.text3')}}</div>
              <div class="pledge_text">{{info.count==undefined?0:info.count}}{{$t('pledgeList.text1')}}</div>
            </div>
          </div>
          <!-- <i class="pledge_open ifont icone-shouqi hand" :style="index==show_pledge?'transform: rotate(180deg)':''" @click="index==show_pledge?show_pledge=-1:show_pledge=index" /> -->
        </div>
        <!-- 进度条 -->

        <div class="pledge_box2 pledge_title flex_row" style="padding: 5px 10px;margin: 10px 0;" v-for="(item,index) in userInfo" :key="index">
          <div>{{$t('pledgeList.text14')}}{{item[0]==0?'已赎回':item[0]}}份</div>
          <div class="pledge_jdt"><p :style="{width:item[1]+'%'}" /></div>
          <div>{{(item[1]).toFixed(2)}}%</div>
          <div class="shuhui" v-if="item[1]==100" @click="redeemMt(index)">赎回</div>
        </div>
      
      </div>
    <div class="pledge" >
      <div class="pledge_box2" v-if="nav>0">
            <div class="flex_lr" style="padding: 5px 0;">
              <div class="pledge_title">数量</div>

            </div>
            <div class="pledge_box flex_lr" style="padding: 5px 10px;">
              <input class="pledge_input" v-model="mt_num" type="number" :placeholder="$t('pledgeList.text13')">
            </div>
            <div style="font-size:0.7rem;margin:5px 0;">MT{{$t('pledgeList.text10')}}：{{info.blance_mt}}MT</div>
         
          </div>

        <div class="nav flex_center hand" v-if="nav==0" @click="nav=1" >我要质押</div>
        <div class="nav flex_center hand" style="background:#989898" v-else-if="mt_num==''" >请输入数量</div>
        <div class="nav flex_center hand"  v-else-if="info.approve_mt==0" @click="approveMT">授权MT</div>
        <div class="nav flex_center hand"  v-else @click="releaseMt">质押MT</div>
        <div class="nav flex_center hand"  v-if="this.newTime>=100" @click="redeemMt">赎回</div>

    </div>
    </div>
  
  </div>
</template>

<script>
import walletHelper from "@/utils/walletHelper.js"
import { Loading } from 'element-ui';

export default {
  components: {},
  data() {
    return {
      show_pledge:-1,
      loading:0,
      nav:0,
      info:[],
      userInfo:[],
      newTime:0,
      ulm_num:{},
      mt_num:'',
    }
  },
  async mounted () {
    await this.$onLaunched;
    this.init();
  },
  methods: {
    init() {
      const that=this
 
      walletHelper.getContract('mt').methods.balanceOf(walletHelper.getAddress()).call().then(res=>{
          console.log(res)
          that.$set(that.info,'blance_mt',walletHelper.Wei(res))
      })
      walletHelper.getContract('mt').methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('mt180')).call().then(res=>{
          console.log(res)
          that.$set(that.info,'approve_mt',walletHelper.Wei(res))
      })
      walletHelper.getContract('mt180').methods.getData(walletHelper.getAddress(),0,99).call().then(res=>{
          console.log(res)
          // res=['2','10000000000000000000','1000000000000000000','1704085912','5000000000000000000','1603221912']
          this.userInfo=[]
         let timeNew=Math.ceil(new Date()/1000)
          for(let i=0;i<res[0];i++){
            let index=i*2+2
            let time=res[index+1]
            let proportion=100
            if(time>0&&timeNew<time){
              proportion=(1-(time-timeNew)/(180*86400))*100
            }
          this.userInfo.push([res[index]/1000000000000000000,proportion])

          }
          that.$set(that.info,'count',res[1]/1000000000000000000)



      })
    },
        fromWei(wei){
      return wei?walletHelper.Wei(wei):0
    },
    dd(){
        this.loading =Loading.service({
            lock: true,
            text: '交易正在处理中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    closeLoading(msg){
      if(msg&&msg!=''){
        this.$toast({
            title: msg
        });
      }
      this.loading&&this.loading.close();
    },

    redeemMt(index){
      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }
      this.sendWeb3(walletHelper.getContract('mt180').methods.take(index)).then(res=>{
          console.log(res)
          that.closeLoading('赎回成功')
          that.init()
      })
    },
    releaseMt(){

      if(this.info.approve_mt==0){
        return
      }

      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }
      const form={
        wallet:walletHelper.getAddress()
      }
      this.get('/api/User/get_parent', form, 'POST').then(res => {
        console.log(res)
        this.sendWeb3(walletHelper.getContract('mt180').methods.release(res.info,String(Math.floor(this.mt_num)))).then(res=>{
          console.log(res)
          that.closeLoading('质押成功')
          that.init()
        })
      })
    },

    approveMT(){
      const that=this
      this.sendWeb3(walletHelper.getContract('mt').methods.approve(walletHelper.getContractAddress('mt180'),walletHelper.toWei('9999999999999999'))).then((result)=>{
        console.log(result)
        walletHelper.getContract('mt').methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('mt180')).call().then(res=>{
            console.log(res)
            that.$set(that.info,'approve_mt',walletHelper.Wei(res))
        })
        that.closeLoading('授权成功')
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.title{
  font-size: 1.1rem;
  font-family: Microsoft YaHei;
  color: #00FFEA;
  margin: 10px 0;
}
  .nav{
    background: #4177c3;
    color: #fbfbfb;
    height: 50px;
    border-radius: 5px;
    margin: 20px;
    font-size: 1rem;
  }
.pledge{
  .pledge_box{
    width: 100%;
    background: #0C2379;
    border-radius: 5px;
    padding: 0 10px;
    color: #fff;
  }
  .pledge_box2{
    width: 100%;
    background: #041453;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
  }
  .pledge_title{
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
  .pledge_text{
    font-size: 0.8rem;
    line-height: 1.5rem;
    color: #00FFEA;
  }
  .pledge_open{
    border-radius: 50%;
    border: 1px solid #00FFEA;
    color: #00FFEA;
    font-size: 0.7rem;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
  }
  .pledge_top{
    border-bottom: 1px solid #0B2FB8;
    padding: 15px 0;
    .pledge_day{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transform: scale(0.8);
    }
  }
  .pledge_jdt{
    width: 0;
    flex: 1;
    height: 6px;
    margin: 0 10px;
    background: #0F5057;
    border-radius: 50px;
    overflow: hidden;
    p{
      height: 100%;
      border-radius: 50px;
      background: #07C6D9;
    }
  }
  .pledge_input{
    background: none;
    color: #fff;
    width: 100%;
  }
  .pledge_input::-webkit-input-placeholder{color:#839CFF;}
  .pledge_input::-moz-placeholder{color:#839CFF;}
  .pledge_input:-ms-placeholder{color:#839CFF;}
  .pledge_btn{
    width: 47%;
    padding: 5px 0;
    color: #000;
    background: #00FFEA;
    border-radius: 5px;
    font-size: 0.7rem;
    margin-bottom: 10px;
  }
}
.shuhui{
      margin: 2px;
    background-color: #1c8299;
    border-radius: 15%;
    padding: 0 5px;
}
</style>